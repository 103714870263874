import {
  Box,
  Button,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { MetaFunction } from '@remix-run/node';
import { useNavigate } from '@remix-run/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AuthLayout from '~/components/Layouts/LandingLayout/AuthLayout';
import Notify from '~/components/Notify/Notify';
import H3 from '~/components/Typograph/H3';
import Subtitle2 from '~/components/Typograph/Subtitle2';
import { useLogin } from '~/services/auth';
import { AuthLoginRequest } from '~/services/auth/AuthLoginService';
import { useAuthStore } from '~/stores/useAuthStores';
import { SetFormError } from '~/utils/form/SetFormError';

export const meta: MetaFunction = () => [{ title: 'Login | Nexus' }];

export default function LoginPage() {
  const { t } = useTranslation();

  const { setAuthenticated, userProp, setUserProps } = useAuthStore();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<AuthLoginRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const { isPending, mutateAsync } = useLogin({
    onError: () => {
      SetFormError<unknown, AuthLoginRequest>(
        setError,
        'login.error.invalid_credentials'
      );
    },
    onSuccess: (data) => {
      Notify.success({
        name: 'Login Successful',
        message: (
          <>
            Welcome back,{' '}
            <Text component="span" fw="bold" c="var(--success-dark)">
              {data?.data.name ?? 'empty'}
            </Text>{' '}
            !
          </>
        ),
      });

      setAuthenticated(
        true,
        data?.data,
        data?.data.access_token ?? '',
        data?.data.refresh_token ?? ''
      );
      setUserProps({
        ...userProp,
        id: data?.data?.id,
        name: data?.data?.name,
        email: data?.data?.email,
        username: data?.data?.username,
        avatar_url: data?.data?.avatar_url,
      });

      setTimeout(() => {
        navigate('/');
      }, 2000);
    },
  });

  const onSubmit: SubmitHandler<AuthLoginRequest> = async (data) => {
    await mutateAsync(data as never);
  };

  return (
    <AuthLayout>
      <Box my="auto" px={24} py={32} component={Stack} justify="center">
        <H3 ta={'center'}>{t('login.button.login')}</H3>
        <Text fz={18} fw={300} c={'var(--text-secondary)'}>
          {t('login.label.welcome_back_admin')}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          <Stack gap={24}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('login.error.username_required'),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('login.error.email_required'),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  label={t('login.label.username')}
                  withAsterisk
                  placeholder={t('login.label.username_placeholder')}
                  error={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: t('login.error.password_required'),
              }}
              render={({ field, fieldState: { error } }) => (
                <PasswordInput
                  label={t('login.label.password')}
                  withAsterisk
                  placeholder={t('login.label.password_placeholder')}
                  error={error?.message}
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack gap={0} align="center">
            <Button
              type="submit"
              loading={isPending}
              mt={60}
              fullWidth
              disabled={!isValid}
            >
              {t('login.button.login')}
            </Button>

            {errors.root && (
              <Subtitle2 mt={8} c="var(--error-main)">
                {t(
                  errors.root.message?.toString() ??
                    'login.error.invalid_credentials'
                )}
              </Subtitle2>
            )}
          </Stack>
        </form>
      </Box>
    </AuthLayout>
  );
}
